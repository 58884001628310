//Main Route

import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import ErrorBoundry from "./Navigation/ErrorClass";
import { Spin } from "antd";
import AdminLogin from "./Containers/AdminFolder/AdminLogin";

//Componnets to be render
const LoginContainer = lazy(() =>
  import("./Containers/onboarding/LoginContainer")
);
const HomeContainer = lazy(() => import("./Containers/HomeContainer"));

const checkAuth = () => {
  //this.props.dispatch(checkToken());
  const token = localStorage.getItem("userid");
  //const refreshToken = localStorage.getItem("refreshToken");
  if (!token) {
    return false;
  }
  return true;
};

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkAuth() ? <Component {...props} /> : <Redirect to="/Register" />
    }
  />
);
const example = {
  margin: "0px auto",
  width: "100%",
  height: "50em",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};
const App = () => {
  return (
    <ErrorBoundry>
      <Suspense fallback={<Spin style={example} size="large" />}>
        <Switch>
          <Route path="/" exact component={withRouter(LoginContainer)} />
          <Route path="/admin" exact component={withRouter(AdminLogin)} />
          {/* <Route path="/Register" exact component={withRouter(RegistrationForm)} />
                <Route path="/ForgotPassword" exact component={withRouter(ForgotPassword)} />    
                <Route path="/events/:eventseo" exact component={withRouter(EventUrl)}/> */}
          {/* <AuthRoute path="/Home" component={withRouter(HomeContainer)} /> */}
          <Route path="/Home" component={withRouter(HomeContainer)} />
          {/* <Route path="/Home/Tracking" component={withRouter(Tracking)} /> */}
        </Switch>
      </Suspense>
    </ErrorBoundry>
  );
};
export default App;
