import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// import { persistStore } from 'redux-persist'
import rootReducer from "./combineReducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// let persistor = persistStore(store)

export { store };
