import React from "react";
import { Card } from "antd";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  componentDidCatch(error, info) {
    this.setState({
      hasError: true
    });
  }
  render() {
    if (this.state.hasError) {
      return (
        <div style={{ background: "#ECECEC", padding: "30px" }}>
          <Card
            title="Sorry Something went wrong!!!"
            bordered={false}
            style={{ width: 300 }}
          >
            <h1>Sorry Something went wrong!!!</h1>
          </Card>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
