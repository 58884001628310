import * as actionCreators from "../actionTypes";

const initialState = {
  search_table: "",
  list: []
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.SEARCH_DATA:
      return {
        ...state,
        search_table: action.payload
      };

    case actionCreators.SEARCH_RESULT:
      return {
        ...state,
        list: action.payload
      };

    case actionCreators.SEARCH_DATA_CLEAR: {
      return {
        ...state,
        search_table: action.payload
      };
    }

    default:
      return { ...state };
  }
};
export default eventsReducer;
