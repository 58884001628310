import React, { Component } from "react";
import { Form, Input, Button, Typography, message } from "antd";

import "../AdminContainer/Styles/main.css";
import { appLogin } from "../../Services/auth.service";
import history from "../../Store/history";

class AdminLoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      autoCompleteResult: [],
      formerror: false
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        appLogin(values).then(res => {
          if (res.status == 200) {
            if (res.data.success == true) {
              localStorage.setItem("usertoken", res.data.token);
              return history.push("/home");
            } else {
              message.error("email or password  incorrect ");
            }
          } else {
            message.log("Something went wrong");
          }
        });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { Title } = Typography;
    return (
      <div className="login-container">
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <img
            src={require("../../images/front.jpg")}
            style={{ height: 150, width: 150, margin: "auto" }}
          />{" "}
          <Title className="title-text">Admin Login</Title>
        </div>

        <Form
          onSubmit={this.handleSubmit}
          className="login-form"
          hideRequiredMark={true}
        >
          <Form.Item label="Email Address">
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "Please input your Email Address!" }
              ]
            })(
              <Input
                className="text-box"
                size="large"
                placeholder="your@email.com"
              />
            )}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password!" }
              ]
            })(
              <Input
                className="text-box"
                size="large"
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              className="submit-btn"
              type="primary"
              block
              size="large"
            >
              Log In
            </Button>
          </Form.Item>
        </Form>
        {this.state.formerror ? <h1>err</h1> : null}
      </div>
    );
  }
}
const AdminLogin = Form.create({ name: "Login" })(AdminLoginForm);

export default AdminLogin;
