import Axios from "axios";
import { base_url } from "../Config/AppConfig";
import history from "../Store/history";

export const appLogin = async params => {
  const resp = await Axios.post(base_url + "account/login", params)
    .then(response => response)
    .catch(function(error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
  return resp;
};

export const appRegister = async params => {
  const resp = await Axios.post(base_url + "account/register", params)
    .then(response => response)
    .catch(function(error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
  return resp;
};

export const addCustomerAddress = async params => {
  const resp = await Axios.post(base_url + "customer/addcustomer", params)
    .then(response => response)
    .catch(function(error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
  return resp;
};
export const allCustomerAddress = async data => {
  const resp = await Axios.post(base_url + "customer/", data)
    .then(response => response)
    .catch(function(error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
  return resp;
};

export const allAccountUser = async () => {
  const resp = await Axios.get(base_url + "account/alluser")
    .then(response => response)
    .catch(function(error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
  return resp;
};

export const deleteAccount = async id => {
  const resp = await Axios.delete(base_url + "account/" + id)
    .then(response => response)
    .catch(function(error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
  return resp;
};

export const appLogout = () => {
  try {
    localStorage.removeItem("roleid");
    localStorage.removeItem("usertoken");
    localStorage.clear();
    history.push("/");
    return true;
  } catch (error) {
    alert(error);
  }
};

// for customer
export const allCustomer = async () => {
  const resp = await Axios.get(base_url + "customer/")
    .then(response => response)
    .catch(function(error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
  return resp;
};

export const deleteCustomer = async id => {
  const resp = await Axios.delete(base_url + "customer/" + id)
    .then(response => response)
    .catch(function(error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
  return resp;
};
